import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import CardComparisonTable from 'components/Card/CardComparisonTable/CardComparisonTable';
import CardBenefitsList, { BenefitItemProps } from 'components/Card/CardBenefitsList/CardBenefitsList';
import { FlowComponentType } from 'routes/types';
import { setApplicationFlow } from 'handlers/applicationStep';
import useCurrentFlow from 'hooks/useCurrentFlow';
import { CurrentFlow } from 'enums/CurrentFlow';

import { ReactComponent as TrophyIcon } from 'images/trophy-card-benefit-icon.svg';
import { ReactComponent as SmartMoneyMovesIcon } from 'images/smart-money-moves.svg';
import { ReactComponent as BlueCalendarIcon } from 'images/blue-calendar-card-benefit-icon.svg';
import { ReactComponent as MoneyIcon } from 'images/money-card-benefit-icon.svg';

import styles from './BuildCreditAvoidDebtSaveMoney.module.scss';

const cardBenefitsList: BenefitItemProps[] = [
  {
    icon: <TrophyIcon />,
    title: 'Rewards for Nurses',
    description: (
      <>
        Earn a projected <span>$500 in rewards</span> over the next year!
      </>
    ),
  },
  {
    icon: <SmartMoneyMovesIcon />,
    title: 'Smart Spending Limits',
    description: (
      <>
        <span>Spend less</span> and get AI coaching to keep you in budget.
      </>
    ),
  },
  {
    icon: <BlueCalendarIcon />,
    title: 'Smart Auto Pay',
    description: (
      <>
        Always pay just enough. <span>Cut interest by up to 90%</span> compared to the average credit card.
      </>
    ),
  },
  {
    icon: <MoneyIcon />,
    title: 'Import Existing Debt',
    description: (
      <>
        <span>Save on average of $6,000</span> when you import your other balances.
      </>
    ),
  },
];

const BuildCreditAvoidDebtSaveMoney = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatch = useDispatch();
  const { isCardFlow } = useCurrentFlow();

  useEffect(() => {
    if (!isCardFlow) {
      dispatch(setApplicationFlow({ currentFlow: CurrentFlow.Card }));
    }
  }, []);

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title="Build Credit, Avoid Debt, and Save Money."
        subtitle={
          <div className={styles.subtitle}>
            Your benefits are worth up to <span>$3,500 per year*.</span>
          </div>
        }
      >
        <CardBenefitsList listBenefits={cardBenefitsList} />
        <div className={styles.comparisonContainer}>
          <div className={styles.comparisonHeader}>
            <span>Compare to Other Cards</span>
            <p>Get the best of credit and debit cards: build credit while avoiding debt.</p>
          </div>

          <CardComparisonTable />
        </div>
        <Button type={ButtonType.Primary} onClick={handleNext}>
          Next
        </Button>
      </FormContainer>
      <div className={styles.disclaimerContainer}>
        <p>
          * Displayed terms are expected and <span>subject to change.</span> Rewards are based on an an estimated
          average spending of $2,000 monthly and average revolve behavior. Estimated savings is based on expected usage
          over 12 months. Plannery APR is based on our recent average personal loan APR. Credit Card APR is based on the
          market average.
        </p>
      </div>
    </>
  );
};

export default BuildCreditAvoidDebtSaveMoney;
