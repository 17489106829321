import { DebtSummary, GetApplicationData } from 'handlers/applicationData';

import { CardColor } from 'components/CardFlow/Customize/cardVersions';
import { ReferralProgram } from 'handlers/cardData';
import { ProfessionGroup } from 'enums/ProfessionGroup';
import { PartnerId } from 'enums/PartnerName';

import Api, { FetchMethodType } from './Api';
import { UtmTags } from './LoanOfferApi';

export interface CardApplyResponse {
  applicationId: string;
  borrowerId: string;
  referralLink: string;
  referredBy: string;
  applied: boolean;
  debtSummary: DebtSummary;
  debtConsolidationPossible: boolean;
  waitListPosition: number;
  originalWaitListPosition: number;
  totalDebt: number | null;
  cardColor: CardColor;
  borrowerCredentials: string;
  referralProgram: ReferralProgram;
  waitListLength: number;
  professionGroup: ProfessionGroup;
  totalAnnualIncome: number;
}

export interface CardApplyData {
  email: string;
  firstName: string;
  lastName: string;
  professionGroup: ProfessionGroup;
  credentials: string;
  phoneNumber: string;
  cardColor: CardColor;
  methodEntityId?: string;
  cardReferredBy?: string;
  utm: UtmTags;
  httpReferrer?: string;
  resumeLink?: string;
  partnerId?: PartnerId;
  sessionToken?: string;
  timezone: string;
  smartSpendingLimitsEnabled?: boolean;
  smartAutoPayEnabled?: boolean;
  importDebtEnabled?: boolean;
}

export interface CardApplyWithApplicationData {
  applicationId: string;
  httpReferrer?: string;
  referredBy?: string;
  resumeLink?: string;
}

export interface GetCardApplicationResponse {
  application: GetApplicationData;
  waitListLength: number;
}

export interface CardApi {
  getCardDataForLoanApplication(applicationId: string): Promise<GetCardApplicationResponse>;
  joinWaitList(data: CardApplyData): Promise<CardApplyResponse>;
  joinWaitListWithLoanOffer(data: CardApplyWithApplicationData): Promise<CardApplyResponse>;
}

export default class CardRestApi extends Api implements CardApi {
  async getCardDataForLoanApplication(applicationId: string): Promise<GetCardApplicationResponse> {
    return this.fetch<GetCardApplicationResponse>(`/card/${applicationId}`);
  }

  async joinWaitList(data: CardApplyData): Promise<CardApplyResponse> {
    return this.fetch<CardApplyResponse>('/card/joinWaitList', {
      method: FetchMethodType.POST,
      body: data,
    });
  }

  async joinWaitListWithLoanOffer(data: CardApplyWithApplicationData): Promise<CardApplyResponse> {
    return this.fetch<CardApplyResponse>(`/card/joinWaitList/${data.applicationId}`, {
      method: FetchMethodType.POST,
      body: {
        referredBy: data.referredBy,
        httpReferrer: data.httpReferrer,
      },
    });
  }
}
