import { CardApi, CardApplyData, CardApplyWithApplicationData } from 'api/CardApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorResponse } from './ErrorResponse';

export const createCardJoinWaitListThunk = (api: CardApi) =>
  createAsyncThunk('card/joinWaitList', async (data: CardApplyData, thunkApi) => {
    try {
      return await api.joinWaitList(data);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });

export const createCardJoinWaitListWithLoanOfferThunk = (api: CardApi) =>
  createAsyncThunk('card/joinWaitListWithLoanOffer', async (data: CardApplyWithApplicationData, thunkApi) => {
    try {
      return await api.joinWaitListWithLoanOffer(data);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });
