import React, { useEffect } from 'react';

import FormNavigation from 'components/FormNavigation';
import Button, { ButtonType } from 'components/Button/Button';
import FormContainer from 'components/LoanForm/FormContainer';
import { FlowComponentType } from 'routes/types';
import { useDispatch, useSelector } from 'react-redux';
import { getCardData } from 'selectors/getCardData';
import { CurrentOnboardingSteps, setCardData, setCurrentOnboardingStep } from 'handlers/cardData';

import { ReactComponent as MoneyIcon } from 'images/money-card-benefit-icon.svg';
import { ReactComponent as CalendarIcon } from 'images/calendar-card-benefit-icon.svg';
import { ReactComponent as TrophyIcon } from 'images/trophy-card-benefit-icon.svg';
import { ReactComponent as PlanneryLogo } from 'images/plannery.svg';
import { ReactComponent as BankIcon } from 'images/bank-rounded.svg';

import BenefitsList, { BenefitItemProps } from 'components/Card/BenefitsList';

import styles from './ImportDebt.module.scss';

const benefitsList: BenefitItemProps[] = [
  {
    icon: <MoneyIcon />,
    title: (
      <>
        Save <span>$6,000</span>¹ on interest.
      </>
    ),
  },
  {
    icon: <CalendarIcon />,
    title: (
      <>
        Save <span>6 years</span> of time in debt.
      </>
    ),
  },
  {
    icon: <TrophyIcon />,
    title: (
      <>
        Earn <span>1,000</span> bonus points.
      </>
    ),
  },
];

const ImportDebt = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatch = useDispatch();
  const cardData = useSelector(getCardData);
  const { currentOnboardingStep } = cardData;

  useEffect(() => {
    if (currentOnboardingStep !== CurrentOnboardingSteps.ImportDebt)
      dispatch(setCurrentOnboardingStep(CurrentOnboardingSteps.ImportDebt));
  }, []);

  const handleNextStep = async (enabled = false) => {
    analytics.track('Import Debt Selected', { enabled });
    dispatch(setCardData({ ...cardData, importDebtEnabled: enabled }));
    handleNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} currentOnboardingStep={currentOnboardingStep} />
      <FormContainer
        title="Import Your Balances and Save"
        subtitle="You can bring this balance to Plannery to access below market interest rates exclusively for healthcare professionals."
      >
        <div className={styles.containerBalanceDiagram}>
          <div className={styles.cardBalance}>
            <BankIcon />
            <div className={styles.cardBalanceTitle}>Your Balance</div>
          </div>
          <div className={styles.cardBalance}>
            <PlanneryLogo className={styles.companyLogo} />
            <div className={styles.cardBalanceTitle}>Plannery</div>
          </div>
        </div>

        <BenefitsList className={styles.benefitsListContainer} benefits={benefitsList} />

        <p className={styles.preferenceText}>
          <span>Save Your Balance Import Preference</span>²
        </p>

        <div className={styles.buttonsContainer}>
          <Button type={ButtonType.Primary} onClick={() => handleNextStep(true)}>
            Opt In When Available
          </Button>
          <Button type={ButtonType.Inverted} onClick={() => handleNextStep()}>
            Opt Out
          </Button>
        </div>
      </FormContainer>
      <div className={styles.disclaimersContainer}>
        <p>¹ Savings estimates are based on our recent personal loan averages and subject to change.</p>
        <p>² Your preference does not guarantee feature availability or approval for Plannery Card.</p>
      </div>
    </>
  );
};

export default ImportDebt;
